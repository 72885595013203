@import 'variables.scss';

@mixin skeleton-height($height-value) {
	.vehicle-card-skeleton {
		&-non-pref {
			min-height: $height-value;
		}

		&-label-pref {
			min-height: calc(#{$height-value} + 22px);
		}

		&-vin-pref {
			min-height: calc(#{$height-value} + 58px);
		}

		&-label-vin-pref {
			min-height: calc(#{$height-value} + 74px);
		}
	}
}

// Animations
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 300ms;
}

.fade-out {
	transition: opacity 300ms ease-in-out;
	opacity: 0;
}

.vehicle-media-container {
	&:before {
		content: '';
		padding-top: 75%;
	}
}

// Responsive tiles
@media screen and (max-width: 1200px) {
	.ws-rec-vehicles .static-width .vehicle-card {
		// Show 3 tiles on smaller screens
		flex: 0.11 0 31%;

		&:nth-child(1n + 4) {
			display: none;
		}
	}

	@include skeleton-height(362px);
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
	// show 4 tiles on medium screens
	.ws-rec-vehicles .static-width .vehicle-card {
		flex: 0.1 0 23%;

		&:nth-child(1n + 5) {
			display: none;
		}
	}
}

@media (min-width: 1200px) {
	// show 4 tiles on large screens
	.ws-rec-vehicles .static-width .vehicle-card {
		flex: 0.1 0 23%;

		&:nth-child(1n + 5) {
			display: none;
		}
	}

	@include skeleton-height(347px);
}

@media only screen and (min-width: 650px) and (orientation: landscape) {
	.vehicle-media-container-mobile {
		height: 240px !important;
	}
}

// Page Elements
.rec-vehicles-container {
	.vehicle-media-container-mobile {
		height: 200px;
	}

	.vehicle-card-skeleton {
		content: '';
		display: block;
		padding: 30% 0 0;
	}

	.vehicle-card {
		transition: background 250ms linear;
		transition: box-shadow 0.2s linear;

		&:hover {
			-webkit-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
			box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);

			.vehicle-card-titles {
				text-decoration: solid underline 1px;
				text-underline-offset: 0.1em;
				margin: 0;
			}
		}
	}

	.vehicle-card-details-container {
		transition-delay: 80ms !important;
	}

	.vehicle-card-description-item {
		line-height: 1.2;
	}

	.oversized-font {
		font-size: 1.75em;
	}

	.paging-dot {
		width: 20px;
		height: 20px;
	}

	.paging-dot > circle {
		r: 6px;
		cx: 10px;
		cy: 10px;
		fill: var(--color-neutral-1000-alpha-8);
	}

	.active .paging-dot > circle {
		fill: $active-paging-dot-color;
	}
}

.section {
	transform-origin: top; // keep the top of the element in the same place
}

.ws-rec-vehicles .hide-from-7 .vehicle-card {
	// Hide 7th tile
	&:nth-child(1n + 7) {
		display: none;
	}
}
